<template>
  <v-dialog v-model="state" persistent max-width="400px">
    <v-card>
      <v-card-title>
        <div
          class="font d-flex flex-row flex-grow-1 justify-center font-weight-medium text-center"
        >
          {{ title }}
        </div>
      </v-card-title>
      <v-card-text class="pa-0 px-3">
        <div class="d-flex flex-column flex-grow-1 justify-center pt-1">
          <p class="font font-weight-medium font-size-md text-center">
            {{ body }}
          </p>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="close" text>
          <span class="font text-capitalize font-weight-medium font-size-md">
            Cancel
          </span>
        </v-btn>
        <v-btn
          :loading="loading"
          :disabled="loading"
          color="primary"
          @click="save"
        >
          <span class="font text-capitalize font-weight-medium font-size-md">
            Yes
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "ConfirmationDialog",
  props: {
    title: {
      type: String,
      default: `Confirm Recording Of Sales`
    },
    state: {
      type: Boolean,
      default: false
    },
    body: {
      type: String,
      default: `This operation is irreversible once it's done. Do you want to
            proceed ?`
    },
    loading: Boolean
  },
  methods: {
    save() {
      this.$emit("save");
    },
    close() {
      this.$emit("close", "alert");
    }
  }
};
</script>
